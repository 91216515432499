import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Api } from '../../services/api.service';
import { CheckoutComponent } from '../checkout/checkout.component';
import { environment } from '../../../environments/environment';
var SubscribeComponent = /** @class */ (function () {
    function SubscribeComponent(route, router, api, modalService) {
        this.route = route;
        this.router = router;
        this.api = api;
        this.modalService = modalService;
        this.isSaved = false;
        this.rfsn = false;
    }
    SubscribeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var params = this.route.snapshot.queryParams;
        this.api.user$.subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = user;
                return [2 /*return*/];
            });
        }); });
        if (params.rfsn) {
            this.rfsn = true;
        }
        this.whitelabelConfig = this.route.snapshot.data.whitelabel;
        this.partnerId = this.whitelabelConfig.id;
        var environmentVar = environment.production ? 'production_plans' : 'development_plans';
        if (this.whitelabelConfig.stripe[environmentVar].premium_plus.invoice_item) {
            this.lifetime = true;
        }
        if (params.coupon) {
            this.coupon = params.coupon;
        }
        this.trialCode = params.code;
        this.utm_source = params.utm_source;
        this.utm_medium = params.utm_medium;
        this.trafficSource = params.source;
        localStorage.setItem("rfsn_v4_id", localStorage.getItem("rfsn_v4_id")),
            localStorage.setItem("rfsn_v4_aid", localStorage.getItem("rfsn_v4_aid")),
            localStorage.setItem("rfsn_v4_cs", localStorage.getItem("rfsn_v4_cs"));
    };
    SubscribeComponent.prototype.canDeactivate = function () {
        return !this.isSaved;
    };
    SubscribeComponent.prototype.checkoutModal = function () {
        this.isSaved = true;
        var checkout = this.modalService.open(CheckoutComponent);
        var component = checkout.componentInstance;
        component.lifetime = this.lifetime;
        component.coupon = this.coupon;
        component.user = this.user;
        component.partnerId = this.partnerId;
        component.rfsn = this.rfsn;
        component.onboardingRedirect = true;
        component.location = 'Signup - Subscribe';
        component.utm_source = this.utm_source;
        component.utm_medium = this.utm_medium;
        component.trafficSource = this.trafficSource;
        component.whitelabel_config = this.whitelabelConfig;
        component.trialCode = this.trialCode;
    };
    SubscribeComponent.prototype.basicModal = function () {
        this.basicRef = this.modalService.open(this.basic, { size: 'lg', centered: true });
    };
    SubscribeComponent.prototype.basicToCheckoutModal = function () {
        this.basicRef.close();
        this.checkoutModal();
    };
    SubscribeComponent.prototype.basicModalClose = function () {
        this.basicRef.close();
    };
    SubscribeComponent.prototype.goBasic = function () {
        this.isSaved = true;
        this.basicRef.close();
        return this.router.navigate(['/onboarding'], {
            queryParams: {
                onboard: true,
                plan: 'free'
            }
        });
    };
    return SubscribeComponent;
}());
export { SubscribeComponent };
