import * as tslib_1 from "tslib";
var _a;
import { AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Api, MessageType, TrialCodes, UPGRADED_EVENT_TYPE } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Membership, Memberships, MembershipSpec } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { DEFAULT_WHITELABEL, findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../models/Authentication';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
var AvailableCurrencies;
(function (AvailableCurrencies) {
    AvailableCurrencies["USD"] = "USD";
    AvailableCurrencies["EUR"] = "EUR";
    AvailableCurrencies["GBP"] = "GBP";
    AvailableCurrencies["CAD"] = "CAD";
    AvailableCurrencies["AUD"] = "AUD";
})(AvailableCurrencies || (AvailableCurrencies = {}));
var currencyLocales = (_a = {},
    _a[AvailableCurrencies.USD] = 'en-US',
    _a[AvailableCurrencies.EUR] = 'de-DE',
    _a[AvailableCurrencies.GBP] = 'en-GB',
    _a[AvailableCurrencies.CAD] = 'en-CA',
    _a[AvailableCurrencies.AUD] = 'en-AU',
    _a);
var TRIAL_MEMBERSHIPS = [Memberships.premiumTrial, Memberships.premiumPlusTrial];
var NON_TRIAL_MEMBERSHIPS = [Memberships.premium, Memberships.premiumPlus];
var TRIAL_PARTNER_PAGES = ['30daytrial', '90daytrial'];
var CheckoutComponent = /** @class */ (function () {
    function CheckoutComponent(api, router, activatedRoute, modalService, cdr) {
        this.api = api;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.modalService = modalService;
        this.cdr = cdr;
        this.onboardingRedirect = false;
        this.updateBilling = false;
        this.lifetime = false;
        this.rfsn = false;
        this.forcePremiumPlus = false;
        this.Memberships = Memberships;
        this.showPromo = false;
        this.loading = false;
        this.couponLoading = true;
        this.alreadyPremium = false;
        this.isFree = false;
        this.selectedCurrency = AvailableCurrencies.USD;
        this.availableCurrencies = [
            AvailableCurrencies.USD,
            AvailableCurrencies.EUR,
            AvailableCurrencies.GBP,
            AvailableCurrencies.CAD,
            AvailableCurrencies.AUD
        ];
        this.PlanBenefits = {
            save: 'Save 90% on every flight, every year',
            domesticDeals: 'Domestic & international flight deals',
            flightDealsPPlus: 'Economy and business-class flight deals',
            fly: 'Fly to Hawaii from $149 or anywhere on your list',
            partnerPerks: 'Exclusive partner perks & discounts',
            cancelAnytime: 'Cancel anytime, no questions asked'
        };
    }
    CheckoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.partnerId) {
            this.whitelabel_config = findWhiteLabel(this.partnerId);
        }
        else if (this.user.partnerId) {
            this.whitelabel_config = findWhiteLabel(this.user.partnerId);
        }
        else {
            this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
        }
        var envStatus = environment.production ? 'production_plans' : 'development_plans';
        if (this.whitelabel_config.stripe[envStatus].premium_plus.invoice_item) {
            this.lifetime = true;
        }
        this.myMembership = Membership.getMembershipSpec(this.user.membershipId);
        this.api.addInitCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.stripe = Stripe(this.api.config.stripe_key);
                this.updateSelectedPlan(this.myMembership.key).then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.updateCurrency()];
                            case 1:
                                _a.sent();
                                if (!this.coupon) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.checkCoupon()];
                            case 2:
                                _a.sent();
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); });
    };
    CheckoutComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.user && this.selectedPlanId && this.whitelabel_config)) return [3 /*break*/, 3];
                        if (!(this.linkAuth && this.paymentEl)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setupElements()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        console.error('linkAuth or paymentEl is null');
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CheckoutComponent.prototype.updateSelectedPlan = function (currentPlan) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.lifetime || (currentPlan > Memberships.free && !this.whitelabel_config.signup.premiumPlus_offer) || this.partnerId === 'premiumplus99' || this.partnerId === 'premiumplus991' || this.partnerId === 'premiumplus992') {
                    this.selectedPlanId = Memberships.premiumPlus;
                }
                else if (this.whitelabel_config.signup.premiumPlus_offer || this.forcePremiumPlus) {
                    this.selectedPlanId = Memberships.premiumPlusTrial;
                }
                else {
                    this.selectedPlanId = Memberships.premiumTrial;
                }
                this.selectedPlan = Membership.getMembershipSpec(this.selectedPlanId);
                this.getLogo(Membership.getMembershipName(this.selectedPlanId));
                return [2 /*return*/];
            });
        });
    };
    CheckoutComponent.prototype.setMembershipPrice = function (selectedPlanId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nonTrialPlanId, membershipName, envStatus, plans, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        nonTrialPlanId = Membership.getNonTrialPlan(selectedPlanId);
                        membershipName = Membership.getMembershipName(nonTrialPlanId);
                        envStatus = environment.production ? 'production_plans' : 'development_plans';
                        plans = this.whitelabel_config.stripe[envStatus];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!this.lifetime) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.api.getInvoicePrice(plans[membershipName].invoice_item)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [4 /*yield*/, this.api.getPlanPrice(plans[membershipName].no_trial).toPromise()];
                    case 4: return [2 /*return*/, _a.sent()];
                    case 5:
                        error_1 = _a.sent();
                        this.error = 'There has been an error, please try again. If the problem persists contact support@dollarflightclub.com';
                        console.error('Error setting membership price:', error_1);
                        return [2 /*return*/, Promise.reject(error_1)];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CheckoutComponent.prototype.updateCurrency = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedLocale, membershipPrice;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedLocale = currencyLocales[this.selectedCurrency];
                        return [4 /*yield*/, this.setMembershipPrice(this.selectedPlanId)];
                    case 1:
                        membershipPrice = _a.sent();
                        if (this.selectedCurrency === AvailableCurrencies.USD) {
                            this.planPrice = this.formatCurrency(selectedLocale, membershipPrice);
                            return [2 /*return*/, this.updateAmountToBeCharged(this.lifetime ? membershipPrice : 1)];
                        }
                        this.api.getCurrencyExchangeRate(this.selectedCurrency).pipe(tap(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var rate;
                            return tslib_1.__generator(this, function (_a) {
                                rate = parseFloat((membershipPrice * response.rate).toFixed(2));
                                this.planPrice = this.formatCurrency(selectedLocale, rate);
                                return [2 /*return*/, this.updateAmountToBeCharged(this.lifetime ? rate : response.rate)];
                            });
                        }); }), catchError(function (error) {
                            console.error('Error fetching currency exchange rate:', error);
                            return of(undefined);
                        })).subscribe();
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutComponent.prototype.updateAmountToBeCharged = function (price) {
        var selectedLocale = currencyLocales[this.selectedCurrency];
        this.amountToBeCharged = this.formatCurrency(selectedLocale, price);
        var pricePattern = /(\$\d+(\.\d+)?)/;
        this.yearlyPriceDisplay = this.whitelabel_config.signup.yearly_price.replace(pricePattern, this.amountToBeCharged);
    };
    CheckoutComponent.prototype.updateCouponDisplayPrice = function (price) {
        var selectedLocale = currencyLocales[this.selectedCurrency];
        this.priceWithCoupon = this.formatCurrency(selectedLocale, price);
        this.cdr.detectChanges();
    };
    CheckoutComponent.prototype.formatCurrency = function (locale, amount) {
        var options = { style: 'currency', currency: this.selectedCurrency };
        if (this.selectedCurrency === AvailableCurrencies.USD) {
            options.minimumFractionDigits = 0;
            options.maximumFractionDigits = 0;
        }
        return new Intl.NumberFormat(locale, options).format(amount);
    };
    CheckoutComponent.prototype.checkCoupon = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.coupon) {
                            this.setCouponError('Please enter a valid promo code.');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.api.checkCoupon(this.coupon).pipe(tap(function (response) {
                                if (response.error) {
                                    _this.setCouponError(response.message);
                                }
                                else if (!response.valid) {
                                    _this.setCouponError('Expired Coupon');
                                }
                                else {
                                    _this.applyValidCoupon(response);
                                }
                            }), catchError(function (error) {
                                console.error('Error checking coupon:', error);
                                _this.setCouponError(error.error.message);
                                return of(undefined);
                            })).subscribe()];
                    case 1:
                        _a.sent();
                        this.couponLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutComponent.prototype.setCouponError = function (message) {
        this.couponError = message;
        this.coupon = '';
        this.discount = '';
    };
    CheckoutComponent.prototype.applyValidCoupon = function (response) {
        var _this = this;
        this.couponError = '';
        this.discount = response.percent_off ? response.percent_off + "%" : "" + response.amount_off / 100;
        if (!this.planPrice) {
            this.setCouponError("Coupon didn't load correctly. Please try again.");
            return;
        }
        var planPriceWithoutSymbol = this.planPrice.replace(/[\$\€\£\s]/g, '');
        planPriceWithoutSymbol = planPriceWithoutSymbol.replace(',', '.');
        var planPriceNumber = parseFloat(planPriceWithoutSymbol);
        if (this.selectedCurrency === 'USD') {
            var newPrice = response.percent_off
                ? (planPriceNumber - (planPriceNumber * response.percent_off / 100))
                : (planPriceNumber - (response.amount_off / 100));
            this.updateCouponDisplayPrice(parseFloat(String(newPrice)));
        }
        this.api.getCurrencyExchangeRate(this.selectedCurrency).pipe(tap(function (currencyResponse) {
            var rate = currencyResponse.rate;
            var couponAmountInSelectedCurrency = response.amount_off / 100 * rate;
            var newPrice = response.percent_off
                ? (planPriceNumber - (planPriceNumber * response.percent_off / 100))
                : (planPriceNumber - couponAmountInSelectedCurrency);
            _this.updateCouponDisplayPrice(parseFloat(String(newPrice)));
        }), catchError(function (error) {
            console.error('Error fetching currency exchange rate:', error);
            return of(undefined);
        })).subscribe();
        this.showPromo = false;
    };
    CheckoutComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        try {
                            //Track conversion with Refersion
                            if (this.api.user.cartId && this.activatedRoute.snapshot.params.partner_id === 'financebuzztrial') {
                                r.addTrans({
                                    'order_id': this.api.genUniqueId(),
                                    'currency_code': 'USD',
                                    'cart_id': this.user.cartId
                                });
                                r.addCustomer({
                                    'email': this.user.email,
                                });
                                r.addItems({
                                    'sku': 'TRIAL_SIGN_UP',
                                    'quantity': 1,
                                    'price': 0
                                });
                                r.sendConversion();
                            }
                        }
                        catch (e) {
                            console.warn(e);
                        }
                        if (gtag) {
                            gtag('event', 'membership', {
                                'event_category': 'upgrade',
                                'event_label': 'Upgrade from basic in members portal',
                                'value': this.planPrice
                            });
                        }
                        return [4 /*yield*/, this.stripe.confirmSetup({
                                elements: this.elements,
                                confirmParams: {},
                                redirect: 'if_required'
                            }).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var rfsn, response;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (res.error && res.error.type === 'validation_error') {
                                                this.validationErr = res.error.message;
                                                this.loading = false;
                                                return [2 /*return*/];
                                            }
                                            if (!(res.setupIntent.status === 'succeeded')) return [3 /*break*/, 4];
                                            if (res.error) {
                                                this.error = res.error.message;
                                                this.loading = false;
                                                return [2 /*return*/];
                                            }
                                            if (!(!this.lifetime && this.rfsn)) return [3 /*break*/, 2];
                                            rfsn = {
                                                cart: this.user.stripeCustomerId,
                                                id: localStorage.getItem('rfsn_v4_id'),
                                                url: window.location.href,
                                                aid: localStorage.getItem('rfsn_v4_aid'),
                                                cs: localStorage.getItem('rfsn_v4_cs')
                                            };
                                            r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
                                            return [4 /*yield*/, this.api.setReferral(this.user.id, UPGRADED_EVENT_TYPE)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [4 /*yield*/, this.api.upgrade(this.user, this.selectedPlanId, this.whitelabel_config, this.coupon, res.setupIntent.id, this.trialCode, res.setupIntent.payment_method, this.lifetime, this.location, this.updateBilling, this.utm_source, this.utm_medium, this.trafficSource)];
                                        case 3:
                                            response = _a.sent();
                                            if (response.error) {
                                                this.loading = false;
                                                this.error = 'We could not process your payment. Please try again. If you continue to have issues, please contact support@dollarflightclub.com. Note: we do not accept prepaid cards.';
                                                return [2 /*return*/];
                                            }
                                            this.loading = false;
                                            this.modalService.dismissAll();
                                            if (this.updateBilling) {
                                                window.location.href = '/profile/account';
                                                return [2 /*return*/];
                                            }
                                            if (this.onboardingRedirect) {
                                                return [2 /*return*/, this.router.navigate(['/onboarding'], {
                                                        queryParams: {
                                                            onboard: true,
                                                            plan: this.selectedPlan.basePlan
                                                        }
                                                    })];
                                            }
                                            window.location.href = '/dashboard';
                                            _a.label = 4;
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.log(err_1);
                        this.error = 'Unable to process payment information. Please try again.';
                        this.loading = false;
                        this.api.showMessage(MessageType.error, 'Unable to process payment information.');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CheckoutComponent.prototype.tryAgain = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalService.dismissAll();
                return [2 /*return*/];
            });
        });
    };
    CheckoutComponent.prototype.getTrialPeriod = function () {
        if (this.whitelabel_config.signup.yearly_price === 'Try for $1') {
            return '14-day';
        }
        else {
            return '';
        }
    };
    CheckoutComponent.prototype.modalInfo = function () {
        if (this.trialCode && !this.coupon) {
            return {
                modalTitle: "<p class=\"plan-price\"> Try for " + this.amountToBeCharged + " | Cancel Anytime | <br> " + this.planPrice + "/year after " + this.trialCodeToDays(this.trialCode) + " trial</p>",
                buttonCTA: "Try for " + this.amountToBeCharged
            };
        }
        else if (this.trialCode && this.coupon && !this.couponLoading) {
            return {
                modalTitle: "<p class=\"plan-price\"> Try for " + this.amountToBeCharged + " | " + this.priceWithCoupon + " for first year after " + this.trialCodeToDays(this.trialCode) + " trial | <br> " + this.planPrice + "/year after</p>",
                buttonCTA: "Try for " + this.amountToBeCharged
            };
        }
        else if (this.partnerId === 'premiumplus99' && this.coupon && !this.couponLoading) {
            return {
                modalTitle: "<p class=\"plan-price\">Only " + this.priceWithCoupon + " for first year | 90-day money-back guarantee | " + this.planPrice + "/year after</p>",
                buttonCTA: "Pay " + this.priceWithCoupon
            };
        }
        else if (TRIAL_PARTNER_PAGES.includes(this.partnerId) && this.coupon && !this.couponLoading) {
            return {
                modalTitle: "<p class=\"plan-price\">" + this.yearlyPriceDisplay + " | " + this.priceWithCoupon + " for first year | " + this.planPrice + "/year after</p>",
                buttonCTA: "Try For " + this.amountToBeCharged
            };
        }
        else if (this.lifetime && !this.coupon) {
            return {
                modalTitle: "<p class=\"plan-price\">One Payment for Lifetime Access | Save 93% Today </p>",
                buttonCTA: "Pay " + this.amountToBeCharged
            };
        }
        else if (this.lifetime && this.coupon && !this.couponLoading) {
            return {
                modalTitle: "<p class=\"plan-price\">One Payment for Lifetime Access | Save 93% Today </p>",
                buttonCTA: "Pay " + this.priceWithCoupon
            };
        }
        else if (NON_TRIAL_MEMBERSHIPS.includes(this.selectedPlanId) && !this.coupon) {
            return {
                modalTitle: "<p class=\"plan-price\">" + this.planPrice + "/year | Cancel Anytime</p>",
                buttonCTA: "Pay " + this.planPrice
            };
        }
        else if (NON_TRIAL_MEMBERSHIPS.includes(this.selectedPlanId) && this.coupon && !this.couponLoading) {
            return {
                modalTitle: "<p class=\"plan-price\">Only " + this.priceWithCoupon + " for first year | Cancel Anytime | " + this.planPrice + "/year after</p>",
                buttonCTA: "Pay " + this.priceWithCoupon
            };
        }
        else if (TRIAL_MEMBERSHIPS.includes(this.selectedPlanId) && !this.coupon) {
            return {
                modalTitle: "<p class=\"plan-price\">" + this.yearlyPriceDisplay + " | Cancel Anytime | " + this.planPrice + "/year after " + this.getTrialPeriod() + " trial</p>",
                buttonCTA: "Try For " + this.amountToBeCharged
            };
        }
        else if (TRIAL_MEMBERSHIPS.includes(this.selectedPlanId) && this.coupon && !this.couponLoading) {
            return {
                modalTitle: "<p class=\"plan-price\">Only " + this.priceWithCoupon + " for first year | Cancel Anytime | " + this.planPrice + "/year after</p>",
                buttonCTA: "Try For " + this.amountToBeCharged
            };
        }
        else if (!this.updateBilling) {
            return {
                modalTitle: "<p class=\"plan-price\">Only " + this.planPrice + "/year | Cancel Anytime</p>",
                buttonCTA: "Pay " + this.planPrice
            };
        }
        else {
            return {
                modalTitle: '<h3>Update Your Payment Details<h3>',
                buttonCTA: 'Update Card'
            };
        }
    };
    CheckoutComponent.prototype.trialCodeToDays = function (trialCode) {
        if (!trialCode) {
            return '';
        }
        if (trialCode === TrialCodes.TwoWeekTrial) {
            return '14 day';
        }
        if (trialCode === TrialCodes.OneMonthTrial) {
            return '30 day';
        }
        if (trialCode === TrialCodes.ThreeMonthTrial) {
            return '3 month';
        }
        if (trialCode === TrialCodes.SixMonthTrial) {
            return '6 month';
        }
    };
    CheckoutComponent.prototype.getLogo = function (plan) {
        this.logo = this.whitelabel_config.logos[plan];
        this.getBenefits();
        if (this.updateBilling) {
            this.logo = 'https://dfcapp.s3.amazonaws.com/Dollar-Flight-Club-Logo-Original-2.png';
        }
    };
    CheckoutComponent.prototype.getBenefits = function () {
        if (this.lifetime) {
            this.benefits = [
                this.PlanBenefits.save,
                this.PlanBenefits.fly,
                this.PlanBenefits.domesticDeals,
                this.PlanBenefits.flightDealsPPlus
            ];
        }
        else if (this.selectedPlanId === Memberships.premium || this.selectedPlanId === Memberships.premiumTrial) {
            this.benefits = [
                this.PlanBenefits.save,
                this.PlanBenefits.fly,
                this.PlanBenefits.domesticDeals,
                this.PlanBenefits.cancelAnytime
            ];
        }
        else if (this.selectedPlanId === Memberships.premiumPlus || this.selectedPlanId === Memberships.premiumPlusTrial) {
            this.benefits = [
                this.PlanBenefits.save,
                this.PlanBenefits.fly,
                this.PlanBenefits.domesticDeals,
                this.PlanBenefits.flightDealsPPlus,
                this.PlanBenefits.partnerPerks,
                this.PlanBenefits.cancelAnytime
            ];
        }
    };
    CheckoutComponent.prototype.setupElements = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, appearance, clientSecret, _a, linkAuthenticationElement, paymentElementOptions, paymentElement, err_2;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.api.setupIntents(this.user)];
                    case 1:
                        response = _b.sent();
                        appearance = {
                            theme: 'stripe',
                            labels: 'floating',
                            variables: {
                                colorPrimary: '#0570de',
                                colorBackground: '#ffffff',
                                colorText: '#30313d',
                                colorDanger: '#df1b41',
                                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                spacingUnit: '2px',
                                borderRadius: '4px',
                            }
                        };
                        this.user = response.user;
                        clientSecret = response.setupIntent.client_secret;
                        _a = this;
                        return [4 /*yield*/, this.stripe.elements({ appearance: appearance, clientSecret: clientSecret })];
                    case 2:
                        _a.elements = _b.sent();
                        linkAuthenticationElement = this.elements.create('linkAuthentication');
                        linkAuthenticationElement.mount(this.linkAuth.nativeElement);
                        linkAuthenticationElement.value = this.user.email;
                        linkAuthenticationElement.on('change', function (event) {
                            _this.api.userEmail = event.value.email;
                        });
                        paymentElementOptions = {
                            layout: 'tabs',
                            defaultValues: {
                                billingDetails: { email: this.user.email }
                            },
                            terms: {
                                card: 'never'
                            }
                        };
                        paymentElement = this.elements.create('payment', paymentElementOptions);
                        paymentElement.mount(this.paymentEl.nativeElement);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _b.sent();
                        console.log(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return CheckoutComponent;
}());
export { CheckoutComponent };
